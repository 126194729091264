import { GenericModel } from '@/shared/model/generic-model';
import { UserField } from '@/modules/auth/user-field';
import { i18n } from '@/i18n';
import { LanguageField } from '../language/language-field';
import { ExamOutputField } from '../exam-output/exam-output-field';
import IntegerField from '../../shared/fields/integer-field';
import { ScoreOutputField } from './score-output-field';

function label(name) {
  return i18n(`assignPapers.fields.${name}`);
}

function getPapers(fieldName) {
  let papers = ExamOutputField.relationToMany(
    fieldName,
    label(fieldName),
    {
      required: true,
    },
  );
  papers.forFormCast = function() {
    return papers.__proto__.forFormCastCustom('idObj');
  };

  return papers;
}

const fields = {
  teachers: UserField.relationToMany(
    'teachers',
    label('teachers'),
    {
      required: true,
      min: 1, //gradingCount
    },
  ),
  examPapers: getPapers('examPapers'),
  unscoredPapers: getPapers('unscoredPapers'),
  uncompletedPapers: getPapers('uncompletedPapers'),
  assignedScores: ScoreOutputField.relationToMany(
    'assignedScores',
    label('assignedScores'),
    {
      required: true,
    },
  ),
  language: LanguageField.relationToOne(
    'language',
    label('language'),
    {
      required: true,
    },
  ),
  gradingCount: new IntegerField(
    'gradingCount',
    label('gradingCount'),
    {
      required: true,
    },
  ),
};

export class AssignPapersModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
