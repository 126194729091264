<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="assignPapers.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="assignPapers.title" />
      </h1>
      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>
      <!--    FORM    -->
      <div class="edit-form">
        <div class="edit-fixed-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doReset"
            icon="el-icon-fa-undo"
          >
            <app-i18n code="common.reset"></app-i18n>
          </el-button>
          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="assignPapers"
          class="form"
          ref="form"
          v-if="model"
        >
          <div class="two-side-center settings-bottom">
            <el-form-item
              :label="fields.language.label"
              :prop="fields.language.name"
              :required="fields.language.required"
            >
              <app-language-autocomplete-input
                :fetchFn="fields.language.fetchFn"
                :mapperFn="fields.language.mapperFn"
                mode="single"
                @input="onlanguagechange"
                :value="model[fields.language.name]"
                @fetched="onlanguagefetched"
              ></app-language-autocomplete-input>
            </el-form-item>
            <el-form-item
              :label="fields.gradingCount.label"
              :prop="fields.gradingCount.name"
              :required="fields.gradingCount.required"
              class="two-side-center assign-specific marleft"
            >
              <el-input-number
                :precision="0"
                :step="1"
                :min="1"
                :max="10"
                @change="onGradingCountChange"
                v-model="model[fields.gradingCount.name]"
              ></el-input-number>
            </el-form-item>
          </div>

          <div class="two-side assign-specific">
            <el-form-item
              :label="getLabelWithLength('teachers')"
              :prop="fields.teachers.name"
              :required="fields.teachers.required"
              class="ar-form-item label-styled"
            >
              <div class="select-boxes-teachers">
                <app-iam-user-autocomplete-input
                  :fetchFn="fetchTeacherACwithLang"
                  :mapperFn="fields.teachers.mapperFn"
                  @fetched="onteachersfetched"
                  v-model="model[fields.teachers.name]"
                  mode="multiple"
                  :key="'users-' + languageKey"
                  :placeholder="getPlaceholder('selectteacher')"
                ></app-iam-user-autocomplete-input>
              </div>
            </el-form-item>
            <el-tabs tab-position="top" v-model="activeTab">
              <el-tab-pane
                name="examPapers"
                :label="getLabelWithLength('examPapers')"
              >
                <div v-if="activeTab === 'examPapers'">
                  <el-form-item
                    :prop="fields.examPapers.name"
                    :required="fields.examPapers.required"
                    class="ar-form-item"
                  >
                    <div class="select-boxes">
                      <app-exam-output-autocomplete-input
                        :fetchFn="examPapersAutoComplete"
                        :mapperFn="fields.examPapers.mapperFn"
                        @fetched="onexampaperfetched"
                        v-model="model[fields.examPapers.name]"
                        mode="multiple"
                        :key="'examPapers-' + languageKey"
                        :placeholder="getPlaceholder('selectpaper')"
                      ></app-exam-output-autocomplete-input>
                    </div>
                  </el-form-item>
                  <div class="centerInRow marHorizontal">
                    <el-button
                      :disabled="saveLoading"
                      @click="setPapersAsUnScored"
                      icon="el-icon-fa-chevron-right"
                    >
                      <app-i18n code="assignPapers.button.unscore"></app-i18n>
                    </el-button>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                name="unscoredPapers"
                :label="getLabelWithLength('unscoredPapers')"
              >
                <div>
                  <el-form-item
                    v-if="activeTab === 'unscoredPapers'"
                    :prop="fields.unscoredPapers.name"
                    :required="fields.unscoredPapers.required"
                    class="ar-form-item"
                  >
                    <div class="select-boxes">
                      <app-exam-output-autocomplete-input
                        :fetchFn="unscoredPapersAutoComplete"
                        :mapperFn="fields.unscoredPapers.mapperFn"
                        @fetched="onunscoredfetched"
                        v-model="model[fields.unscoredPapers.name]"
                        mode="multiple"
                        :key="'unscoredPapers-' + languageKey"
                        :placeholder="getPlaceholder('selectpaper')"
                      ></app-exam-output-autocomplete-input>
                    </div>
                  </el-form-item>
                  <div class="centerInRow marHorizontal">
                    <el-button
                      :disabled="saveLoading"
                      @click="deleteUnScoredItems"
                      icon="el-icon-fa-chevron-right"
                    >
                      <app-i18n code="assignPapers.button.deleteAll"></app-i18n>
                    </el-button>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                name="assignedScores"
                :label="getLabelWithLength('assignedScores')"
              >
                <el-form-item
                  v-if="activeTab === 'assignedScores'"
                  :prop="fields.assignedScores.name"
                  :required="fields.assignedScores.required"
                  class="ar-form-item"
                >
                  <div class="select-boxes">
                    <app-score-output-autocomplete-input
                      :fetchFn="assignedScoresAutoComplete"
                      :mapperFn="fields.assignedScores.mapperFn"
                      @fetched="onscoresfetched"
                      v-model="model[fields.assignedScores.name]"
                      mode="multiple"
                      :key="'assignedScores-' + languageKey"
                      :placeholder="getPlaceholder('selectitem')"
                    ></app-score-output-autocomplete-input>
                  </div>
                </el-form-item>
              </el-tab-pane>
              <el-tab-pane
                name="uncompletedPapers"
                :label="getLabelWithLength('uncompletedPapers')"
              >
                <div v-if="activeTab === 'uncompletedPapers'">
                  <el-form-item
                    :prop="fields.uncompletedPapers.name"
                    :required="fields.uncompletedPapers.required"
                    class="ar-form-item"
                  >
                    <div class="select-boxes">
                      <app-exam-output-autocomplete-input
                        :fetchFn="uncompletedPapersAutoComplete"
                        :mapperFn="fields.uncompletedPapers.mapperFn"
                        @fetched="onuncompletedfetched"
                        v-model="model[fields.uncompletedPapers.name]"
                        mode="multiple"
                        :key="'uncompletedPapers-' + languageKey"
                        :placeholder="getPlaceholder('selectpaper')"
                      ></app-exam-output-autocomplete-input>
                    </div>
                  </el-form-item>
                  <div class="centerInRow marHorizontal">
                    <el-button
                      :disabled="saveLoading"
                      @click="deleteUncompletedPapers"
                      icon="el-icon-fa-chevron-right"
                    >
                      <app-i18n
                        code="assignPapers.button.deleteUncompleted"
                      ></app-i18n>
                    </el-button>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

          <div class="centerInRow">
            <el-button
              v-if="activeTab === 'examPapers'"
              :disabled="saveLoading"
              @click="assignExamPapers"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="assignPapers.button.examPapers"></app-i18n>
            </el-button>
            <el-button
              v-if="activeTab === 'unscoredPapers'"
              :disabled="saveLoading"
              @click="assignUscoredPapers"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="assignPapers.button.unscoredPapers"></app-i18n>
            </el-button>
            <el-button
              v-if="activeTab === 'uncompletedPapers'"
              :disabled="saveLoading"
              @click="assignUncompletedPapers"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="assignPapers.button.assignUncompleted"></app-i18n>
            </el-button>
            <el-button
              v-if="activeTab === 'assignedScores'"
              :disabled="saveLoading"
              @click="reassignScores"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="assignPapers.button.assignedScores"></app-i18n>
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { routerAsync } from '@/app-module';
import { FormSchema } from '@/shared/form/form-schema';
import { AssignPapersModel } from '../assign-papers-model';
import { mapGetters, mapActions } from 'vuex';
import { IamService } from '../../iam/iam-service';
import AppExamOutputAutocompleteInput from '../../exam-output/components/exam-output-autocomplete-input';
import { ExamOutputService } from '../../exam-output/exam-output-service';
import { ScoreOutputService } from '../../score-output/score-output-service';
const { fields } = AssignPapersModel;
import examOutputStatus from '@/modules/exam-output/static/exam-output-status';
import { i18n } from '@/i18n';
import AppScoreOutputAutocompleteInput from '../components/score-output-autocomplete-input';
//teachers ilk sirada tut, asagida count'a gore formSchema ilk elemani (teachers) min value set ediliyor.
const formSchema = new FormSchema([
  fields.teachers,
  fields.examPapers,
  fields.uncompletedPapers,
  fields.unscoredPapers,
  fields.assignedScores,
  fields.language,
]);
export default {
  name: 'assign-papers',
  components: {
    AppScoreOutputAutocompleteInput,
    AppExamOutputAutocompleteInput
  },
  data() {
    return {
      rules: formSchema.rules(),
      languageKey: 'DEFAULT_LANG_KEY', //language her degistiginde user cekmek icin
      model: null,
      fetchTeacherACwithLang: null,
      examPapersAutoComplete: null,
      unscoredPapersAutoComplete: null,
      uncompletedPapersAutoComplete: null,
      assignedScoresAutoComplete: null,
      hasTeachersFetched: false,
      hasPapersFetched: false,
      activeTab: 'examPapers',
    };
  },
  watch: {
    activeTab: function(tabName) {
      let papers = [
        'examPapers',
        'unscoredPapers',
        'assignedScores',
        'uncompletedPapers'
      ];
      papers = papers.filter(p => p !== tabName);
      //Clear other list
      papers.forEach(p => (this.model[this.fields[p].name] = []));
    }
  },
  created() {
    this.model = formSchema.initialValues(this.record || {});
    this.model[this.fields.gradingCount.name] = 1;
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      saveLoading: 'scoreOutput/assign/saveLoading'
      //findLoading: 'scoreOutput/form/findLoading',
    }),
    findLoading() {
      return !(this.hasTeachersFetched && this.hasPapersFetched);
    },
    record() {
      return {};
      //teachers and examOutputs
    },
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      assignPapersIS: 'scoreOutput/assign/assignPapers',
      setPapersAsUnScoredIS: 'scoreOutput/assign/setPapersAsUnScored',
      reassignScoresIS: 'scoreOutput/assign/reassignScores',
      _deleteUnScoredPapers: 'scoreOutput/assign/deleteUnScoredPapers',
      _deleteUncompletedPapers: 'scoreOutput/assign/deleteUncompletedPapers',
      _assignUncompletedPapers: 'scoreOutput/assign/assignUncompletedPapers'
    }),
    getLabelWithLength(fieldName) {
      return (
        fields[fieldName].label +
        (this.model[fields[fieldName].name] &&
        this.model[fields[fieldName].name].length > 0
          ? ' (' + this.model[fields[fieldName].name].length + ')'
          : '')
      );
    },
    getAssignBtnLabel() {
      return i18n('assignPapers.button.' + this.activeTab);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      routerAsync().push('/');
    },
    getPlaceholder(value) {
      return i18n(`assignPapers.placeholders.${value}`);
    },
    onteachersfetched(value) {
      this.model[fields.teachers.name] = value;
      this.hasTeachersFetched = true;
    },
    onexampaperfetched(value) {
      this.model[fields.examPapers.name] = value;
      this.hasPapersFetched = true;
    },
    onunscoredfetched(value) {
      this.model[fields.unscoredPapers.name] = value;
      this.hasPapersFetched = true;
    },
    onuncompletedfetched(value) {
      this.model[fields.uncompletedPapers.name] = value;
      this.hasPapersFetched = true;
    },
    onscoresfetched(value) {
      this.model[fields.assignedScores.name] = value;
      this.hasPapersFetched = true;
    },
    onlanguagefetched(value) {
      const language = value.filter(v => v.code === 'en')[0];
      if (language) {
        this.onlanguagechange(language);
      }
    },
    onlanguagechange(lang) {
      this.model[fields.language.name] = lang;
      //Reset oncesi English'de iken reset sonrasi tekrar English secildiginde verilerin gelmesi icin random bi deger de ekledim.
      this.languageKey =
        lang.id.toString() + Math.floor(Math.random() * 100).toString();

      this.fetchTeacherACwithLang = IamService.fetchTeacherACwithLangs([
        lang.id
      ]);

      this.setExamOutputAutoCompFunc(
        'examPapersAutoComplete',
        lang.id,
        examOutputStatus.EXAM_FINISHED
      );
      this.setExamOutputAutoCompFunc(
        'unscoredPapersAutoComplete',
        lang.id,
        examOutputStatus.UNSCORE
      );
      this.setExamOutputAutoCompFunc(
        'uncompletedPapersAutoComplete',
        lang.id,
        examOutputStatus.ANSWERED
      );
      this.setScoreOutputAutoCompFunc('assignedScoresAutoComplete', lang.id);
    },
    setExamOutputAutoCompFunc(funcName, langId, eoStatus) {
      this[funcName] = async (query, limit) => {
        const filter = {
          examLanguageId: langId,
          outputStatus: eoStatus
        };
        return await ExamOutputService.listAutocomplete(query, limit, filter);
      };
    },
    setScoreOutputAutoCompFunc(funcName, langId) {
      this[funcName] = async (query, limit) => {
        const filter = { examLanguageId: langId };
        return await ScoreOutputService.listAutocomplete(query, limit, filter);
      };
    },
    onGradingCountChange(value) {
      //Bir cevabi kac hoca okuyacaksa girilecek min hoca miktari o olmalidir.
      if (formSchema.fields.length > 0) {
        formSchema.fields[0].min = value;
        this.rules = formSchema.rules();
      }
    },
    assignExamPapers() {
      this.assignPapers('examPapers');
    },
    assignUscoredPapers() {
      this.assignPapers('unscoredPapers');
    },
    assignUncompletedPapers() {
      this.assignPapers('uncompletedPapers');
    },
    assignPapers(paperFieldName) {
      this.runWithFormValidation(payload => {
        payload.paperFieldName = paperFieldName;
        this.assignPapersIS(payload);
      });
    },
    deleteUnScoredItems() {
      this.runWithFormValidation(async payload => {
        await this._deleteUnScoredPapers(payload.unscoredPapers);
        this.activeTab = 'examPapers';
        setTimeout(() => {
          this.activeTab = 'unscoredPapers';
        }, 500);
      });
    },
    deleteUncompletedPapers() {
      this.runWithFormValidation(async payload => {
        await this._deleteUncompletedPapers(payload.uncompletedPapers);
        this.activeTab = 'examPapers';
        setTimeout(() => {
          this.activeTab = 'uncompletedPapers';
        }, 500);
      });
    },
    setPapersAsUnScored() {
      this.runWithFormValidation(async payload => {
        await this.setPapersAsUnScoredIS(payload);
        this.activeTab = 'unscoredPapers';
      });
    },
    reassignScores() {
      this.runWithFormValidation(payload => {
        return this.reassignScoresIS(payload);
      });
    },
    async runWithFormValidation(runOnSuccess) {
      try {
        //form rules'a gore validation yap
        await this.$refs.form.validate();
        console.log(this.model);
        //yup ile schema'ya gore alanlar cast edilir
        const payload = formSchema.cast(this.model);
        //console.log(`Payload: ${JSON.stringify(payload)}`)
        runOnSuccess(payload);
      } catch (error) {
        console.log('ErrorZ: ' + error);
      }
    }
  }
};
</script>

<style scoped>
.settings-bottom {
  border-bottom: 1px solid #efefef;
  margin-bottom: 1rem;
}

.two-side {
  display: flex;
  justify-content: space-evenly;
}

.two-side-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.select-boxes {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.select-boxes-teachers {
  width: 380px;
}
.ar-form-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 360px;
  margin: 0 1rem 1rem 1rem;
}
.centerInRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.marleft {
  margin-left: 90px;
}

.marHorizontal {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>

<style>
.assign-specific .el-form-item__content {
  margin-left: 0 !important;
}

.assign-specific .el-tabs.el-tabs--right {
  height: inherit !important;
}
.assign-specific .el-tabs__item {
  color: #1c295c;
}

.two-side > .el-form-item > .el-form-item__label {
  text-align: left;
}

.el-form-item.two-side-center > label {
  width: 200px !important;
}

.centerInRow input.el-input__inner {
  text-align: center;
}

.centerInRow li.el-select-dropdown__item {
  text-align: center;
}
</style>
