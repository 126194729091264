export default {
  DEFAULT: 0,
  FETCHED: 1,
  ANSWERED: 2,
  EXAM_FINISHED: 3, //assign teachers asamasina geciyor bundan sonra
  ASSIGNED: 5, //Will Read by Teacher
  UNSCORE: 51,
  //ASSIGNED2: 5,
  AUTO_SCORE: 6,
  RESULTED: 7, //5 ve 6'ya geri donus olabilsin diye 7 ve 8 yaptim.
  AUTO_RESULTED: 8
};
